// Colors
$primary:      #3454FA;
$success:      #00C269;
$warning:      #E0AF00;
$danger:       #F63E3E;
$white:        #FFFFFF;


$theme-colors: (
  "primary":    $primary,
  "success":    $success,
  "warning":    $warning,
  "danger":     $danger,
);

@each $color, $value in $theme-colors {
    .icon-soft-#{$color} {
        background-color: rgba($value, .15);
        border: 1px solid rgba($value, .20) !important;
        color: $value;
    }
    .text-#{$color} {
        color: $value !important;
    }
    .btn-#{$color} {
        background-color: $value;
        border: none;
    }

}
// font-size, icon-size
@for $i from 10 through 100 {
    .fs-#{$i} {font-size: 1px * $i; }
}

// helper css
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Configuration
@import "../node_modules/bootstrap/scss/functions";
@import "./scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";
@import "./scss/components/table";


@import "./scss/components/form";
@import "./scss/components/toastify";
@import "../node_modules/bootstrap/scss/mixins/banner";
@import "./scss/components/datatable";
@import "./scss/variables";
@include bsBanner("");


body {
    background-color: #EDF0F6;
    color: #232A34;
    font-family: "Poppins", sans-serif;
}
.avarat-icon {
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    i {
        font-size: 72px;
    }
}
.text-muted {
    color: #808A98 !important;
}
.bg-light {
    background-color: #F1F4F8 !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    line-height: 1.25;
}
// Layout css
.page-wrapper {
    padding: 0 48px;
}
.topbar {
    padding: 24px 0;
}
.card {
    border-radius: 16px;
    border: none;
    box-shadow: 0px 2px 30px rgba(35, 42, 52, .15);
    margin-bottom: 24px;
    .card-body {
        padding: 24px;
        .card-title {
            margin-bottom: 24px;
        }
    }
}
.chart-img {
    -webkit-animation:spin 6s linear infinite;
    -moz-animation:spin 6s linear infinite;
    animation:spin 6s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

/* Sidebar.css */
.custom-sidebar .offcanvas-header {
  background-color: #FFFFFF;
  color: #3454FA;
}

.custom-sidebar .offcanvas-body {
  background-color: #FFFFFF;
  color: #3454FA;
}

.custom-sidebar .offcanvas-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.custom-nav .custom-nav-link {
  color: #3454FA;
  font-size: 1.1rem;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.custom-nav .custom-nav-link:hover {
  background-color: #3454FA;
  color: #FFFFFF;
}

.custom-hamburger {
  background-color: #FFFFFF;
  border: none;
  color: #3454FA;
  padding: 10px 15px;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: background-color 0.3s;
  display: flex;
  height: 50px;
}

.custom-hamburger:hover {
  background-color: #3454FA;
}

// Responsive css
@media (max-width: 767.98px) {
    .page-wrapper {
        padding: 0 12px;
    }
}
.auto-width-dropdown {
    width: auto;
    display: inline-block;
  }
  
  .user-details-container {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
}

.custom-sidebar.collapsed .custom-nav-link {
    text-align: center;
  }
  
  .custom-sidebar.collapsed .custom-nav-link i {
    margin-right: 0;
  }
  
  .custom-sidebar.expanded .custom-nav-link {
    text-align: left;
  }
  
  .custom-sidebar.expanded .custom-nav-link i {
    margin-right: 10px;
  }
  