// datatable.scss
.dataTables_wrapper {
    .dataTables_length { 
      margin: 5px;
    }
    .dataTables_filter {
        margin-bottom: calc($grid-gutter-width / 2);
    }
    table.dataTable>thead>tr>th, 
    table.dataTable>thead>tr>td {
        border-bottom: none;
    }
    table.dataTable thead th, 
    table.dataTable tfoot th {
        font-weight: $font-weight-medium;
    }
    .dt-buttons {
        float: left;
        margin-right: 10px;
        display: flex;
        grid-gap: 0 12px;
    }
    &.dtl-right {
        .dataTables_length {
            float: right;
            margin-right: 12px;
            margin: 5px;
        }
    }
    .dataTables_length {
        label {
            font-size: $font-size-sm;
            color: $text-muted;
            margin: 5px;
        }
        select {
            border: $form-select-border-width solid $form-select-border-color;
            color: $input-color;
            border-radius: $input-border-radius;
            margin: 0 4px 0 8px; // Added space after "entries per page"
            &:focus-visible {
                outline: none;
            }
        }
    }
    .dataTables_filter {
        input[type="search"] {
            border: $form-select-border-width solid $form-select-border-color;
            color: $input-color;
            border-radius: $input-border-radius;
            margin-left: 4px;
            padding: 5px 10px;
            outline-offset: 0;
            &:focus-visible {
                border-color: $input-focus-border-color;
                outline: none;
            }
            &::placeholder {
                font-size: $input-font-size;
                color: $input-placeholder-color;
            }
        }
    }
    table.dataTable {
        border-collapse: collapse;
        border-bottom: $form-select-border-width solid $table-border-color;
        border-radius: $input-border-radius;
        overflow: hidden;

        thead>tr>th.sorting:before, 
        thead>tr>th.sorting:after, 
        thead>tr>th.sorting_asc:before, 
        thead>tr>th.sorting_asc:after, 
        thead>tr>th.sorting_desc:before, 
        thead>tr>th.sorting_desc:after, 
        thead>tr>th.sorting_asc_disabled:before, 
        thead>tr>th.sorting_asc_disabled:after, 
        thead>tr>th.sorting_desc_disabled:before, 
        thead>tr>th.sorting_desc_disabled:after, 
        thead>tr>td.sorting:before, 
        thead>tr>td.sorting:after, 
        thead>tr>td.sorting_asc:before, 
        thead>tr>td.sorting_asc:after, 
        thead>tr>td.sorting_desc:before, 
        thead>tr>td.sorting_desc:after, 
        thead>tr>td.sorting_asc_disabled:before, 
        thead>tr>td.sorting_asc_disabled:after, 
        thead>tr>td.sorting_desc_disabled:before, 
        thead>tr>td.sorting_desc_disabled:after {
            line-height: 9px;
            font-size: .6em;
        }
    }
    .dataTables_paginate,
    .dataTables_paginate span {
        display: flex;
        align-items: center;
    }
    .dataTables_info {
        padding-top: 16px;
        color: $text-muted;
    }
    .dataTables_paginate {
        padding-top: calc($grid-gutter-width / 2);
        margin-bottom: 10px;
        .paginate_button {
            background-color: $light;
            height: 28px;
            width: 100%;
            font-size: 14px;
            color: $dark !important;
            padding: 6px 9.5px;
            height: 28px;
            min-width: 28px !important;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 3px;
            border-radius: $pagination-border-radius;
            &.next {
                margin-right: 0;
            }
            &.current, &:hover, &:focus, &:active, &.current:hover, &.current:focus {
                background: $primary !important;
                color: $white !important;
                border-color: $primary !important;
                box-shadow: none;
            }
            &.disabled {
                &:hover {
                    background: transparent !important;
                    color: $dark !important;
                    border-color: transparent !important;
                }
            }
        }
    }
    .dataTables_empty {
        display: none;
    }
}