// form.scss

  // input-icon-content
  .input-icon-content {
    position:relative;
    .input-icon {
        position: absolute;
        top: 9px;
        right:16px;
        color: #212529;
        z-index: 1;
    }
  }
  form.was-validated {
    .input-icon-content {
        .input-icon {
            right:36px;
        }
    }
  }
  .custom-fr-group {
    margin-bottom: 16px;
  }
  